import React from 'react';
import FormationComponent from './FormationComponent';
import CareerComponent from './CareerComponent';

function AboutComponent (){
 return (
   <div>
      <FormationComponent/>
      <CareerComponent/>
   </div>
 )
}

export default AboutComponent;
